import React from 'react';
import styled, { css } from 'styled-components';
import { ImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import Comment from '../icons/comment';
import { displayDateDiff, formatDate } from '../atoms/date/utils';
import { useTranslation } from '../utils/translation';
import { DEVICE_SIZE, mq } from '../utils/mq';

export interface PostItemProps {
  className?: string;
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentsNb?: number;
  mainTag?: string;
  mode?: string;
  authors?: string;
  previousDate?: string;
  separator?: string;
}

const Top = styled.div`
  color: var(--theme-color-foreground);
  display: flex;
  font-size: 13px;
  font-weight: 700;
  gap: calc(var(--spacer-s) / 1.25);
  line-height: 1.62;
  text-transform: uppercase;
  transition: color 0.3s;

  ${mq.lte(
    1100,
    css`
      .mod-large & {
        display: none;
      }
    `,
  )}
`;

const Title = styled.p`
  color: var(--theme-color-foreground);
  font-size: 16px;
  line-height: 1.62;
  transition: color 0.3s;
`;

const Post = styled.article`
  border-top: solid 1px var(--color-alt-2);
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
  justify-content: stretch;
  text-decoration: none;
  padding: var(--spacer-s-fluid) 0;

  &:hover ${Top}, &:hover ${Title} {
    color: var(--theme-color-primary);
  }

  &.mod-first {
    border-top: none;
    padding: 0 0 var(--spacer-s-fluid);
  }

  ${mq.lte(
    1100,
    css`
      &.mod-large {
        border-top: none;
      }
    `,
  )}
`;

const PostMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .mod-magazine & {
    flex-direction: row;
    gap: var(--spacer-s);
  }

  ${mq.lte(
    1100,
    css`
      .mod-large & {
        ${Title} {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          font-family: var(--theme-typography-primary-font);
          font-size: 19px;
          font-weight: 700;
          line-height: 1.38;
          margin: 0 0 var(--spacer-xs) 0;
        }

        picture {
          margin: 0 0 var(--spacer-s);
          width: 100%;
        }

        img {
          object-fit: cover;
        }
      }
    `,
  )}
`;

const PostImage = styled.div`
  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      //display: none;
    `,
  )}
`;

const Comments = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;
`;

const Tag = styled.div`
  flex: 1;
`;

const PublishDate = styled.span`
  color: var(--theme-colors-foreground);
`;

const Bottom = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  margin: auto 0 0;
  white-space: nowrap;

  .mod-card & {
    padding: 0 var(--spacer-s) var(--spacer-s);
  }

  ${mq.gte(
    1100,
    css`
      display: none;
    `,
  )}
`;

const Informations = styled.span`
  flex: 1;
`;

const CommentsBottom = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;
`;

const SeparatorDate = styled.div`
  background: var(--theme-color-background);
  text-align: center;
  position: relative;
  padding: var(--spacer-sm-fluid) 0;
  margin: -2px 0;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      &.mod-large {
        display: none;
      }
    `,
  )}

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--color-alt-3);
  }
`;

const SeparatorDateLabel = styled.span`
  background: var(--theme-color-background);
  color: ${(props) => (props.theme.isDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)')};
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 var(--spacer-s);
  position: relative;
  z-index: 1;
`;

export const PostItemMix: any = (props: PostItemProps) => {
  const { image, title, date, link, mainTag, commentsNb, className, mode, authors, separator } = props;

  const LinkComponent = useLinkComponent();
  const t = useTranslation();

  const imageProps = {
    ...image,
    mobileWidth: mode === 'magazine' ? 70 : 360,
    mobileHeight: mode === 'magazine' ? 60 : 204,
    desktopWidth: mode === 'magazine' ? 70 : 240,
    desktopHeight: mode === 'magazine' ? 60 : 135,
  };

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(date, locale);

  return (
    <>
      {separator && (
        <SeparatorDate className={`mod-${mode}`}>
          <SeparatorDateLabel>{separator}</SeparatorDateLabel>
        </SeparatorDate>
      )}
      <Post className={`mod-${mode} ${className}`}>
        <Top>
          <PublishDate data-testid="meta-date">{formatDate(date, 'HH:mm', t('locale'))}</PublishDate>
          <Tag>{mainTag}</Tag>
          {commentsNb ? (
            <LinkComponent {...link}>
              <Comments>
                <Comment width={15} height={15} color="var(--theme-color-primary)" />
                {commentsNb}
              </Comments>
            </LinkComponent>
          ) : null}
        </Top>
        <PostMain>
          {(mode === 'large' || mode === 'magazine') && (
            <PostImage>
              <LinkComponent {...link}>{mode && <ImageComponent {...imageProps} />}</LinkComponent>
            </PostImage>
          )}
          <LinkComponent {...link}>
            <Title data-testid="meta-label">{title}</Title>
          </LinkComponent>
        </PostMain>
        {mode === 'large' && (
          <Bottom>
            <Informations>
              <PublishDate data-testid="meta-date">{dateTimeAgo},&nbsp;</PublishDate>
              {t('design.postItem.authorLabel')}&nbsp;
              <strong>{authors}</strong>
            </Informations>
            {commentsNb ? (
              <LinkComponent {...link}>
                <CommentsBottom>
                  <Comment width={24} height={24} color="var(--theme-color-primary)" />
                  {commentsNb}
                </CommentsBottom>
              </LinkComponent>
            ) : null}
          </Bottom>
        )}
      </Post>
    </>
  );
};
