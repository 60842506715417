import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ViewLarge from '../icons/view-large';
import ViewMagazine from '../icons/view-magazine';
import ViewCompact from '../icons/view-compact';
import { DEVICE_SIZE, mq } from '../utils/mq';

const Modes = styled.div`
  background-color: var(--color-alt-5);
  border-radius: 50%;
  height: 44px;
  padding: 10px;
  width: 44px;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      display: none;
    `,
  )}

  &.mod-large {
    ${mq.gte(
      DEVICE_SIZE.MEDIUM,
      css`
        display: inherit;
      `,
    )}
  }
  cursor: pointer;
`;

export type ModesType = {
  callback: (mode: string) => void;
  allDevices?: boolean;
  sectionId?: string;
  defaultMode?: string;
};

export const SwitchViewMode: React.FC<ModesType> = ({ callback, allDevices, sectionId, defaultMode }) => {
  const [mode, setMode] = useState(defaultMode || null);

  const ViewFactory = (modeView: string) => {
    switch (modeView) {
      case 'large':
        return ViewLarge;
      case 'magazine':
        return ViewMagazine;
      default:
        return ViewCompact;
    }
  };

  const handleViewChange = () => {
    const key = sectionId ? `feedMode_${sectionId}` : 'feedMode';
    const modeView = localStorage.getItem(key);
    if (modeView === 'large') {
      localStorage.setItem(key, 'compact');
    } else if (modeView === 'compact') {
      localStorage.setItem(key, 'magazine');
    } else {
      localStorage.setItem(key, 'large');
    }
    setMode(localStorage.getItem(key));
    callback(mode);
  };

  const View = ViewFactory(mode);

  useEffect(() => {
    const key = sectionId ? `feedMode_${sectionId}` : 'feedMode';
    if (defaultMode && !localStorage.getItem(key)) {
      callback(defaultMode);
    } else {
      setMode(localStorage.getItem(key));
      callback(mode);
    }
  }, [callback, mode, sectionId, defaultMode]);

  return (
    <Modes onClick={handleViewChange} className={allDevices ? 'mod-large' : ''}>
      <View width={24} height={24} color="var(--text-color-alt)" />
    </Modes>
  );
};

export default SwitchViewMode;
