import React from 'react';
import styled from 'styled-components';

import { GenericItem } from '@marty-js/api-sdk/types';
import { PostItemCompact } from '@marty-js/design/src/molecules/post-item-compact';
import { PostItemLine } from '@marty-js/design/src/molecules/post-item-line';
import { Switch } from '@marty-js/design/src/atoms/switch';
import { formatDate, getHistoryDate } from '../atoms/date/utils';
import { Ad } from '../ads/ad';

type SwitchCompactProps = {
  callback: () => void;
  isCompact: boolean;
};

type PostItemReducerProps = {
  item: GenericItem & {
    authors?: any;
    image?: any;
    commentCount?: any;
    comments?: any;
  };
  sortBy?: string;
  onlyText?: boolean;
};

const SeparatorDate = styled.div`
  background: var(--theme-color-background);
  text-align: center;
  position: relative;
  padding: var(--spacer-sm-fluid) 0;
  margin: -2px 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--color-alt-3);
  }
`;

const SeparatorDateLabel = styled.span`
  background: var(--theme-color-background);
  color: ${(props) => (props.theme.isDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)')};
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 var(--spacer-s);
  position: relative;
  z-index: 1;
`;

const SwitchNews = styled.div`
  margin: 0 auto var(--spacer);
`;

export const generateCompactItem = (
  genericItems: GenericItem[],
  genericItem: GenericItem,
  index: number,
  t: any,
  condition?: boolean,
  mobilePos?: number,
) => {
  const link = {
    href: genericItem.url,
  };

  const date =
    genericItem?.updatedAt > genericItem?.republishedAt ? genericItem?.updatedAt : genericItem?.republishedAt;

  const previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
  const currentDate = new Date(genericItem.republishedAt);
  const label = getHistoryDate(currentDate, previousDate, t('locale'));

  return (
    <>
      {condition && <Ad className={`Mobile_Pos${mobilePos}`} mobileOnly />}
      {label && (
        <SeparatorDate>
          <SeparatorDateLabel>{label}</SeparatorDateLabel>
        </SeparatorDate>
      )}
      <PostItemCompact
        id={genericItem.id}
        key={genericItem.id}
        title={genericItem.title}
        commentCount={genericItem.commentsNb}
        image={{ imageId: genericItem.imageId, alt: genericItem.title }}
        date={formatDate(date, 'HH:mm', t('locale'))}
        authors={genericItem.authors}
        link={link}
      />
    </>
  );
};

export const SwitchCompact = ({ isCompact, callback }: SwitchCompactProps) => {
  return (
    <SwitchNews>
      <Switch isChecked={isCompact} callback={callback} title="Flux condensé" />
    </SwitchNews>
  );
};

export const postItemReducer = ({ item, sortBy }: PostItemReducerProps) => {
  const props: any = {};

  if (!item) {
    return null;
  }

  props.image = { imageId: item?.imageId || item?.image, alt: item.title };
  props.mainTag = item?.mainTag;
  props.sponsored = item.tagSlugs?.includes('sponsored');
  props.commentsNb = item.commentsNb || item.commentCount || item.comments;

  if (sortBy === 'republishedAt') {
    props.date = item?.republishedAt ? item.republishedAt : item?.publishedAt;
  } else {
    props.date = item?.updatedAt ? item.updatedAt : item?.publishedAt;
  }

  props.link = { href: item.url };
  props.title = item.title;

  props.authors = item.authors?.length
    ? item.authors
        .map((author: any) => {
          return author.title ? author.title : author;
        })
        ?.join(', ')
    : null;

  return props;
};

export const generateItem = (genericItem: GenericItem, index: number, t: any, firstDay: boolean, sortBy?: string) => {
  const link = {
    href: genericItem.url,
  };

  let date;
  if (sortBy === 'republishedAt') {
    date = genericItem?.republishedAt ? genericItem.republishedAt : genericItem?.publishedAt;
  } else {
    date = genericItem?.updatedAt ? genericItem.updatedAt : genericItem?.publishedAt;
  }

  const isSponsoredItem = genericItem?.tagSlugs?.includes('sponsored');
  const displayedDate = firstDay
    ? formatDate(date, 'dd MMMM yyyy à HH:mm', t('locale'))
    : formatDate(date, 'dd MMMM yyyy', t('locale'));

  return (
    <>
      {(index + 1) % 10 === 0 && index < 30 && (
        <Ad className={`Mobile_Pos${Math.round((index + 1) / 10)}`} mobileOnly />
      )}
      <PostItemLine
        {...postItemReducer({ item: genericItem })}
        key={genericItem.id}
        date={displayedDate}
        title={genericItem.title}
        link={link}
        sponsoredItem={isSponsoredItem}
      />
    </>
  );
};
